import React, { FC } from 'react';
import { Link } from 'gatsby';
import { EMAIL } from '../../../constants';

export const TabTouUkResidents: FC = () => {
  return (
    <>
      <h3 id="yield-terms-of-use">UK Residents</h3>
      <p>Version date  – October 19, 2023</p>
      <p>
        The services and products described in this section of the website under "Solutions" are "controlled activities" and "controlled investments" within the meaning of the United Kingdom Financial Services and Markets Act 2000 (Financial Promotion) Order 2005 (the <strong>"FPO"</strong>). Their promotion or offering in the United Kingdom is restricted pursuant to the FPO, to, amongst others (a) persons having professional experience of participating in matters relating to investments, falling within Article 19 of the FPO (see detailed definition below) and (b) high net worth bodies corporate, partnerships, unincorporated associations, trusts, etc. falling within Article 49 of the FPO (see detailed definition below) (together, <strong>"Permitted Persons"</strong>).
      </p>
      <h4>Definitions</h4>
      <dl>
        <dt>
          1. Article 19 Investment professionals
        </dt>
        <dd>
          <ul className='ml-4'>
            <li>
              (a) a Financial Conduct Authority or Prudential Regulation Authority authorised person;
            </li>
            <li>
              (b) an exempt person where the communication relates to a controlled activity which is a regulated activity in relation to which the person is exempt;
            </li>
            <li>
              (c) any other person: <br />
              <ul className='ml-8'>
                <li>
                  (i) whose ordinary activities involve him in carrying on the controlled activity to which the communication relates for the purpose of a business carried on by him; or
                </li>
                <li>
                  (ii) who it is reasonable to expect will carry on such activity for the purposes of a business carried on by him;
                </li>
              </ul>
            </li>
            <li>
              (d) a government, local authority (whether in the United Kingdom or elsewhere) or an international organisation;
            </li>
            <li>
              (e) a person ("A") who is a director, officer or employee of a person ("B") falling within any of (a) to (d) where the communication is made to A in that capacity and where A’s responsibilities when acting in that capacity involve him in the carrying on by B of controlled activities.
            </li>
          </ul>
        </dd>
        <dt>
          2. Article 49 High net worth companies, unincorporated associations etc.
        </dt>
        <dd>
          <ul className='ml-4'>
            <li>
              (a) a body corporate which has, or which is a member of the same group as an undertaking which has, a called-up share capital or net assets of not less than: <br />
              <ul className='ml-8'>
                <li>
                  (i) if the body corporate has more than 20 members or is a subsidiary undertaking of an undertaking which has more than 20 members, £500,000;
                </li>
                <li>
                  (ii) otherwise, £5 million;
                </li>
              </ul>
            </li>
            <li>
              (b) an unincorporated association or partnership which has net assets of not less than £5 million;
            </li>
            <li>
              (c) the trustee of a high value trust i.e. a trust where the aggregate value of the cash and investments which form part of the trust’s assets (before deducting the amount of its liabilities) (a) is £10 million or more; or (b) has been £10 million or more at anytime during the year immediately preceding the date on which the communication in question was first made or directed;
            </li>
            <li>
              (d) any person ("A") whilst acting in the capacity of director, officer or employee of a person ("B") falling within any of sub-paragraphs (a) to (c) where A’s responsibilities, when acting in that capacity, involve him in B’s engaging in investment activity.
            </li>
          </ul>
        </dd>
      </dl>
    </>
  );
};
