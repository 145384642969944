import React, { FC } from 'react';
import cx from 'classnames';
import { Link } from 'gatsby';
import { useLocation } from 'react-use';

export enum NavItemType {
  legal,
}

interface Props {
  type?: NavItemType;
  onClick?: (url: string) => void;
  className?: string | undefined;
  selectedCls?: string | undefined;
  linkClassName?: string | undefined;
  selectedLinkCls?: string | undefined;
  shouldParseSearch?: boolean;
}

const getLinks = (type: NavItemType) => {
  switch (type) {
    default:
      return [
        {
          title: 'Privacy policy',
          URL: '/legal/?topic=privacy',
        },
        {
          title: 'Terms of use',
          URL: '/legal/?topic=terms-of-use',
        },
        {
          title: 'Legal notices',
          URL: '/legal/?topic=notice',
        },
        {
          title: 'Licenses',
          URL: '/legal/?topic=licenses',
        },
      ];
  }
};

export const NavItem: FC<Props> = ({
  type = NavItemType.legal,
  onClick,
  className = '',
  selectedCls = 'bg-primary text-primary',
  linkClassName = '',
  selectedLinkCls = 'text-white',
  shouldParseSearch = false,
}) => {
  /**
   * Hooks
   */
  const location = useLocation();

  /**
   * DOM
   */
  const links = getLinks(type);
  return (
    <>
      {links.map(({ URL, title }: any, index: number) => {
        const isSame =
          shouldParseSearch &&
          location &&
          location.search &&
          URL.toLowerCase().includes(location.search.toLowerCase());
        return (
          <li
            key={index}
            onClick={() => {
              if (!onClick) {
                return;
              }
              onClick(URL);
            }}
            className={cx({ [selectedCls]: isSame }, className)}
          >
            <Link to={URL} className={cx({ [selectedLinkCls]: isSame }, linkClassName)}>
              {title}
            </Link>
          </li>
        );
      })}
    </>
  );
};
