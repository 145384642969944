import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { navigate } from 'gatsby';
import { Tab } from '@headlessui/react';
import { TabTouEffective } from './tab-effective';
import { TabTouCustody } from './tab-custody';
import { parse } from 'query-string';

import './index.scss';
import { TabTouUkResidents } from './tab-uk-residents';

export const TermsOfUse = () => {
  /**
   * Vars
   */
  const tabItems = ['general', 'custody-product', 'uk-residents'];

  /**
   * State
   */
  const [tabIndex, setTabIndex] = useState<number>(0);

  /**
   * Methods
   */
  const getClassName = (selected: boolean) => {
    return cx('text-xl font-medium px-1 py-2 border-b-4 cursor-pointer', {
      'border-accent': selected,
      'border-transparent': !selected,
    });
  };
  const handleTabChange = (index: number) => {
    const itemForIndex = tabItems.find((_v, i) => i === index);
    const toUrl = `/legal/?topic=terms-of-use&item=${itemForIndex}`;
    navigate(toUrl);
    setTabIndex(index);
  };

  /**
   * Hooks
   */
  useEffect(() => {
    const { item } = parse(location.search);
    if (!item) {
      return;
    }
    const value = Array.isArray(item) ? item[0] : item;
    const itemIndex = tabItems.findIndex(v => v.toLowerCase() === value.toLowerCase());
    setTabIndex(itemIndex >= 0 ? itemIndex : 0);
  }, []);

  /**
   * DOM
   */
  return (
    <div className="terms-of-use-pages">
      <h3 className="mb-12">Terms of Use</h3>
      <Tab.Group selectedIndex={tabIndex} onChange={handleTabChange}>
        <Tab.List className="flex flex-row flex-wrap gap-y-3 gap-x-5 justify-start mb-8 typo-b2">
          <Tab className={({ selected }) => getClassName(selected)}>Terms of Use</Tab>
          <Tab className={({ selected }) => getClassName(selected)}>Custody Product</Tab>
          <Tab className={({ selected }) => getClassName(selected)}>UK Residents</Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
            <TabTouEffective />
          </Tab.Panel>
          <Tab.Panel>
            <TabTouCustody />
          </Tab.Panel>
          <Tab.Panel>
            <TabTouUkResidents />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};
