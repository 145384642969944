import React, { FC } from 'react';
import { Link } from 'gatsby';
import { EMAIL } from '../../../constants';

export const TabTouCustody: FC = () => {
  return (
    <>
      <h3 id="yield-terms-of-use">STABLEHOUSE CUSTODY PRODUCTS</h3>
      <p>Version date – June 21, 2023</p>
      <p>Last updated – Sept 05, 2023</p>
      <dl>
        <dt>
          <h4>1. Introduction</h4>
        </dt>
        <dd>
          We will be providing certain custody accounts in respect of your digital assets.
          These Custody Terms and Conditions constitute a legally binding agreement
          between the parties. Please read and keep these Custody Terms and Conditions.
          You can contact us by e-mail at{' '}
          <a href={`mailto:${EMAIL.SUPPORT}`}>{EMAIL.SUPPORT}</a> with any questions about
          these Custody Terms and Conditions.
        </dd>
        <dt>
          <h4>2. Definitions and Interpretation</h4>
        </dt>
        <dd>
          Unless stated otherwise, reference is made to the Terms of Use and any
          capitalised terms not otherwise defined herein shall have the meaning to them in
          the Terms of Use.
        </dd>
        <dt>
          <h4>3. Acceptance of terms and conditions</h4>
        </dt>
        <dd>
          By making an application for access to the Stablehouse Custody Products, you
          agree that these Custody Terms and Conditions will govern your Stablehouse
          Custody Accounts in addition to any applicable Terms and Conditions (including
          but not limited to the Terms of Use). Any Stablehouse Custody Accounts
          registered to a user who is granted access to the Stablehouse Custody Product
          will be governed by these Custody Terms and Conditions, as modified from time to
          time. We reserve the right to update these Custody Terms and Conditions at any
          time, and may notify you of such changes via the Site or by email at the address
          specified on your Stablehouse Account, but we have no obligation to notify you
          of every update.
        </dd>
        <dt>
          <h4>4. Conditions of acceptance</h4>
        </dt>
        <dd>
          You can enter into these Custody Terms and Conditions and use the Stablehouse
          Custody Products only if all of the conditions below are met.
        </dd>
        <dd>
          4.1 You have opened a Stablehouse Account and continue to meet all of the terms
          and conditions set out in the Terms of Use and any other applicable Terms and
          Conditions.
        </dd>
        <dd>
          4.2 The representations and warranties given by you in the Terms of Use and in
          these Custody Terms and Conditions are true and accurate and continue to be true
          and accurate at all times during your use of the Stablehouse Custody Product.
        </dd>
        <dd>
          4.3 You have submitted to Stablehouse only complete, correct and up-to-date
          information and documents.
        </dd>
        <dd>
          4.5 By making an application for access to the Stablehouse Custody Product, you
          acknowledge and confirm that you meet all of the conditions set out above. In
          the event that we subsequently ascertain that you have not met or no longer meet
          these conditions, we may suspend provision of the Stablehouse Custody Products
          and any related services to you and close your Stablehouse Accounts. See section
          on Termination below.
        </dd>
        <dd>
          4.6 At any time, and in our sole discretion, without liability to you, we can:
          <ol className="list-roman-lower">
            <li>
              refuse your request to access and use the Stablehouse Custody Product;{' '}
            </li>
            <li>change the Custody Terms and Conditions;</li>
            <li>
              suspend provision of the Stablehouse Custody Product or all or part of the
              Stablehouse Products and services; or
            </li>
            <li>
              change, update, remove, cancel, suspend, disable or discontinue any
              promotion, component, content, incentive or feature of the Stablehouse
              Custody Product.
            </li>
          </ol>
        </dd>
        <dd>
          4.7 You shall not be deemed to have access to a Stablehouse Custody Product and
          we have no responsibility to you unless and until you have received written
          (which may be electronic) confirmation from us that you have been granted access
          to a Stablehouse Custody Product.
        </dd>
        <dt>
          <h4>5. FEATURES OF THE STABLEHOUSE CUSTODY PRODUCTS</h4>
        </dt>
        <dd>
          5.1 You have access to two custody products:
          <ul className="ml-8">
            <li>
              (a) Pooled Custody Trading Accounts: Stablehouse may pool your supported
              digital assets with other customer custodied digital assets in omnibus
              custody wallets, controlled by Stablehouse to provide effective and secure
              digital assets trading services (“Pooled Custody Trading Account”).
            </li>
            <li>
              (b) Segregated Wallets: You may store your supported digital assets in
              individual segregated custody accounts (“Segregated Wallet”, together with
              Pooled Custody Trading Accounts, “Custody Account(s)”.)
            </li>
          </ul>
        </dd>
        <dd>
          5.2 There is currently a minimum amount of digital assets held for custody
          purposes (“Custodied Assets”) in the Custody Accounts required to access the
          Stablehouse Custody Product and we reserve the right to stipulate the minimum or
          maximum custodied amounts on a case by case basis.
        </dd>
        <dd>
          5.3 Following commencement of your access to the Stablehouse Custody Terms and
          Conditions, you can purchase digital assets on the Platform by sending a wire
          transfer from a bank account to Stablehouse and allocate such available digital
          assets for use with the relevant Stablehouse Custody Product on the Platform.
        </dd>

        <dt>
          <h4>5. CUSTODY: OWNERSHIP AND CONTROL OF DIGITAL ASSETS</h4>
        </dt>
        <dd>
          6.1 Title to all supported digital assets in your Custody Accounts shall at all
          times remain with you and shall not transfer to Stablehouse. All interests in
          digital assets held in your Custody Accounts are held for you, are not property
          of Stablehouse and are not subject to the claims of the Company’s creditors. As
          owner of the supported digital assets in your Custody Accounts, you shall bear
          all risk of loss of such supported digital assets. The Company shall have no
          liability for supported digital asset fluctuations or loss.
        </dd>
        <dd>
          6.2 None of the supported digital assets in your Custody Accounts are the
          property of, or shall or may be loaned to, the Company; the Company does not
          represent or treat digital assets in your Custody Accounts as belonging to the
          Company. The Company may not grant any security interest in the supported
          digital assets held in your Custody Accounts. Except as required by law, or
          except as provided herein, Stablehouse will not sell, transfer, loan,
          hypothecate, or otherwise alienate supported digital assets in your Custody
          Accounts unless instructed by you.
        </dd>
        <dd>
          6.3 Supported digital assets held in your Custody Accounts are controlled by you
          whilst being protected by private keys under control of Stablehouse. You may
          withdraw an equivalent amount of digital assets to those shown in your Custody
          Accounts at any time to separate blockchain wallets which you control or other
          third party custodial services in your name.
        </dd>
        <dd>
          6.4 The assets of the Custody Accounts are at all times kept separate from the
          assets of Stablehouse and used exclusively in accordance with any directions you
          give in respect of your Stablehouse Account. All such Digital Asset Transactions
          are registered and recorded in a ledger (“Customer Account Ledger”) which
          clearly identifies the relevant customer Stablehouse Account, Custody Account;
          through which the Digital Asset Transaction is to be executed; and the digital
          asset which is required to be transferred to or from which relevant Custody
          Account.
        </dd>
        <dd>
          6.5 Stablehouse shall on a daily basis, reconcile all customer supported digital
          assets held in Custody Account to the Client Account Ledger and the assets of
          the Custody Account and individual Stablehouse Accounts.
        </dd>
        <dd>
          6.6 You accept and understand that digital assets in your Stablehouse Account do
          not qualify for insurance and/or protection by the Federal Deposit Insurance
          Corporation or the Securities Investor Protection Corporation, or any other
          similar scheme operating or existing in your jurisdiction of residence or
          domicile.
        </dd>
        <dd>
          6.7 You acknowledge and agree that the Site may be subject to scheduled and
          unscheduled maintenance. You agree and understand that part of or all of
          Stablehouse may be periodically unavailable during such times (collectively,
          "Downtime"). You acknowledge and agree that Stablehouse is not responsible or
          liable during periods of Downtime. During such periods of Downtime, the prices
          of various digital assets may move, which means that you may gain or lose value
          in your Stablehouse Account. Stablehouse is not responsible for those gains or
          losses resulting from the Downtime.
        </dd>
        <dd>
          6.8 Digital Assets held in Custody Accounts are subject to the Bermuda Monetary
          Authority Digital Asset Custody Code of Practice (May 2019) (as the same may be
          amended or replaced by the Bermuda Monetary Authority from time to time).
        </dd>

        <dt>
          <h4>7. DIGITAL ASSET TRANSACTIONS</h4>
        </dt>
        <dd>
          7.1 Upon being granted access to Custody services via your Stablehouse Account,
          you will have the ability to trade, convert, transfer, track and store supported
          digital assets by giving instructions through the Platform (each such
          transaction, a “Digital Asset Transaction“) in connection with your Stablehouse
          Custody Account. You will be able to obtain evidence of completion of each
          Digital Asset Transaction through your Stablehouse Account. 7.
        </dd>
        <dd>
          7.2 Stablehouse reserves the right to refuse to process or to cancel any pending
          Digital Asset Transaction in its discretion, which may be due to compliance with
          laws and regulations or in response to a court order, subpoena, other government
          order or to enforce transaction limits or for risk management purposes.
          Stablehouse cannot reverse a Digital Asset Transaction which has been broadcast
          to a digital asset network. In order to provide a valuation of the supported
          digital assets, Stablehouse receives price streams from multiple counterparties
          that have gone through the Company’s due diligence process.
        </dd>
        <dd>
          7.3 You assume full responsibility and liability for any loss resulting from
          intentional or unintentional misuse of your Stablehouse Custody Account,
          including, without limitation, any loss resulting from (i) depositing one type
          of digital asset to a wallet intended for another type of digital asset,
          regardless of whether the relevant digital asset network confirms the applicable
          Digital Asset Transaction; (ii) depositing a digital asset into an address that
          you did not intend; (iii) inserting incorrect transaction information into the
          Platform. Stablehouse assumes no responsibility or liability in connection with
          any of the foregoing.
        </dd>
        <dd>
          7.4 YOUR STABLEHOUSE CUSTODY ACCOUNT AND ANY OTHER ACCOUNT YOU MAY HAVE WITH US
          (AND ANY DIGITAL ASSETS HELD IN SUCH ACCOUNT) IS NOT A BANK ACCOUNT OR AN
          INTEREST BEARING DEPOSIT ACCOUNT. NO INTEREST WILL BE PAID ON ANY DIGITAL ASSETS
          YOU USE TO PURCHASE OR TRADE FOR ANY OTHER DIGITAL ASSET WITH OTHER USERS, AND
          ALL DIGITAL ASSETS DIRECTLY HELD BY US ARE NOT INSURED BY US OR ANY GOVERNMENT
          AGENCY INCLUDING (WITHOUT LIMITATION) WITH RESPECT TO CYBER SECURITY OR THEFT.
          PLEASE NOTE, THE ACCOUNT IS NOT FDIC OR SIPC INSURED. YOU ARE POTENTIALLY
          SUBJECT TO THE CREDIT RISK OF STABLEHOUSE.
        </dd>
        <dd>
          7.5 Stablehouse carries commercial crime and cyber liability insurance that
          protects a portion of digital assets held across our storage systems against
          losses from theft and cybersecurity breaches. However, our policy does not cover
          any losses resulting from unauthorized access to your Stablehouse Account(s) due
          to a breach or loss of your credentials.
        </dd>

        <dt>
          <h4>8. REPRESENTATIONS </h4>
        </dt>
        <p>
          By applying for access to use the StableHouse Custody Products, you represent
          and warrant as set out below.
        </p>
        <dd>
          8.1 You are not a citizen or resident of any country whose legislation conflicts
          with the participation and use by you of the Stablehouse Custody Products.
        </dd>
        <dd>
          8.2 You have sufficient understanding of cryptographic tokens, digital assets,
          token storage mechanics (such as blockchain wallets) and blockchain technology
          to understand these Custody Terms and Conditions and to appreciate the risks and
          implications of the Stablehouse Custody Products.
        </dd>
        <dd>
          8.3 You are in compliance with the Terms of Use and you have read, understand
          and accept these Custody Terms and Conditions.
        </dd>
        <dd>
          8.4 You have obtained sufficient information about the Stablehouse Custody
          Product to enable you to make an informed decision to access and use the
          Stablehouse Custody Product. You have made your own determination as to (and you
          acknowledge that Stablehouse does not make any representation or warranty as to:
          (i) the tax or accounting treatment of the Stablehouse Custody Product and any
          remuneration or other funds received by you in respect thereto; (ii) you are the
          legal and beneficial owner of (or exercise complete discretion over) all Digital
          Assets in your Stablehouse Account free and clear of all liens, claims, security
          interests and encumbrances; and (iii) all transactions you authorize by
          accessing our Stablehouse Custody Product and your acceptance of these Custody
          Terms and Conditions are carried out for your own account and not on behalf of
          any other person or entity.
        </dd>
        <dd>
          8.5 If your Stablehouse Custody Account is registered in the name of a
          corporation, company, limited liability company, limited or general partnership,
          sole proprietorship or other entity holding any account in any capacity other
          than an individual capacity, each person or entity which has completed any
          application for access to use the Stablehouse Custody Account: (i) is fully
          authorised to execute all documents and otherwise complete our requirements in
          their stated capacity; (ii) has furnished all necessary documents and other
          information necessary to demonstrate that authority and; (iii) will furnish all
          other documents and complete other requirements as we may request from time to
          time.
        </dd>
        <dd>
          8.6 Any funds or Digital Assets which are owned by you and used in connection
          with the Stablehouse Custody Product are not derived from money laundering,
          terrorist financing, fraud or any other illegal or criminal activity under any
          applicable law in any jurisdiction.
        </dd>

        <dt>
          <h4>9. Fees </h4>
        </dt>
        <dd>
          9.1 The Company may, at its discretion, charge a fee, payable in fiat (USD), for
          the provision, maintenance and moving of assets in and out of the Custody
          Account. Such fee will be calculated based on the Custodied Assets and will be
          invoiced on a monthly basis. You are obliged to initiate payment of such fees in
          accordance with the terms of the issued invoice.
        </dd>
        <dd>
          9.2 The Company reserves the right to put a “hold” on the amount that it is
          owed. If the outstanding fees are not paid in accordance with the terms of the
          issued invoice, the Company reserves the right to automatically take the amount
          owed from the customer's account, even if this requires liquidation of customer
          assets.
        </dd>
        <dd>
          9.3 The Company reserves the right to immediately suspend your Stablehouse
          Custody Account (and any accounts beneficially owned by related entities or
          affiliates), freeze or lock the Custodied Assets or funds in all such accounts,
          and suspend your access to the Platform following non-payment of any outstanding
          fees.
        </dd>
        <p>
          You agree to pay all applicable fees when you are using the Platform. For a list
          of all fees, please visit:{' '}
          <a
            href="https://app.stablehouse.com/fees"
            title="Fees can be viewed from the footer once you have logged in"
          >
            https://app.stablehouse.com/fees
          </a>
          .
        </p>

        <dt>
          <h4>10. Restricted persons </h4>
        </dt>
        <dd>
          You hereby represent and warrant that you have not been listed on the U.S.
          Treasury Department's Specially Designated Nationals List or the U.S. Commerce
          Department's Denied Persons List, Unverified List, Entity List, or HM Treasury's
          financial sanctions regime and you will not use our Platform to conduct any
          illegal or illicit activity.
        </dd>

        <dt>
          <h4>11. Taxes</h4>
        </dt>
        <dd>
          You are solely responsible for determining whether, and to what extent, any
          taxes apply to any transactions you carry out through the Platform (including,
          without limitation, your use of the Stablehouse Custody Products), and for
          withholding, collecting, reporting and remitting the correct amounts of tax to
          the appropriate tax authorities.
        </dd>

        <dt>
          <h4>12. Risk disclosures</h4>
        </dt>
        <dd>
          12.1 The Stablehouse Custody Product may not be available in jurisdictions which
          Stablehouse determines it would not be able to offer the Product due to
          regulatory or policy reasons.
        </dd>
        <dd>
          12.2 Stablehouse is not a bank or institution and we are not a member of the
          Securities Investor Protection Corporation or insured by the Federal Deposit
          Insurance Corporation or equivalent regulatory regimes.
        </dd>
        <dd>
          12.3 Before using the Stablehouse Custody Products, you should ensure that you
          understand the risks associated with digital assets. Digital assets markets may
          be volatile and subject to significant price fluctuations which could result in
          sudden and significant decreases or increases in the value of your digital
          assets.
        </dd>
        <dd>
          12.4 You should carefully assess whether your financial situation and risk
          tolerance is suitable for buying or selling digital assets. You agree and accept
          that Stablehouse does not guarantee any return on your digital assets.
        </dd>

        <dt>
          <h4>13. Termination of Terms of Use</h4>
        </dt>
        <dd>
          <b>Suspension of Stablehouse Custody Accounts</b>
          <br />
          You agree that we shall have the right to immediately suspend your Stablehouse
          Custody Account (and any accounts beneficially owned by related entities or
          affiliates), freeze or lock the Custodied Assets or funds in all such accounts,
          and suspend your access to Stablehouse for any reason including if we suspect
          any such accounts to be in violation of these Terms and Conditions, our Privacy
          Policy, or any applicable laws and regulations. You agree that we shall not be
          liable to you for any permanent or temporary modification of your Stablehouse
          Custody Account, or suspension or termination of your access to all or any
          portion of our services. We shall reserve the right to keep and use the
          transaction data or other information related to such Stablehouse Custody
          Accounts.
        </dd>

        <dd>
          The above access control may also be applied in the following cases:
          <ul className="list-dot">
            <li>
              Your Stablehouse Custody Account is subject to a governmental proceeding,
              criminal investigation or other pending litigation;
            </li>
            <li>
              We detect unusual activities in or unauthorised access to your Stablehouse
              Custody Account;
            </li>
            <li>
              We are required to do so by a court order or command by a
              regulatory/government authority.
            </li>
          </ul>
        </dd>

        <dd>
          <b>ii. Cancellation of Stablehouse Custody Accounts</b>
          <br />
          In case of any of the following events, we shall have the right to directly
          terminate these Terms of Use by cancelling your Stablehouse Custody Account, and
          shall have the right to permanently or temporarily suspend your Stablehouse
          Custody Account upon the occurrence of the below:
          <ul className="list-dot">
            <li>upon our termination of services to you;</li>
            <li>
              you allegedly register or register any other person’s name as a User more
              than once, directly or indirectly;
            </li>
            <li>
              the information that you have provided is untruthful, inaccurate, outdated
              or incomplete;
            </li>
            <li>
              your application for cancellation of your Stablehouse Custody Account;
            </li>
            <li>you request that our services be terminated; and</li>
            <li>
              any other circumstances where we reasonably deem it appropriate to terminate
              our services within our risk appetite.
            </li>
          </ul>
        </dd>
        <dd>
          Should your Stablehouse Custody Account be terminated, all account and
          transactional information that meet data retention standards will be securely
          stored for at least 5 years in accordance with our Privacy Policy. In addition,
          if a transaction is deemed incomplete during the account termination process, we
          shall have the right to notify you of the process.
        </dd>
        <dd>
          You acknowledge that where you initiate termination of an account, right to
          erasure under GDPR, PIPA or other equivalent regulations, will also be subjected
          to the data retention protocols stated above.
        </dd>
        <dd>
          If we are made aware that any Custodied Assets or funds held in your Stablehouse
          Custody Account are stolen or otherwise are not lawfully possessed by you, we
          may, but have no obligation to, place an administrative hold on the affected
          funds and your Stablehouse Custody Account. We may continue such hold until any
          dispute has been resolved and evidence of the resolution acceptable to us has
          been provided. You agree that we will have no liability or responsibility for
          any such hold, or for your inability to withdraw Custodied Assets or funds or
          execute trades during the period of any such hold.
        </dd>

        <dd>
          <b>iii. Remaining Funds After Stablehouse Custody Account Termination</b>
          <br />
          Except as set forth in paragraph iv below, once a Stablehouse Custody Account is
          closed/withdrawn, all remaining fees, charges and liabilities owed to
          Stablehouse will be netted off immediately. In relation to termination fees,
          Stablehouse shall have the right to charge an administrative fee of US$10 value
          equivalent upon the termination of a Stablehouse Account. Upon netting off all
          outstanding charges to Stablehouse (if any), you will have 30 business days to
          withdraw all Digital Assets or funds from your Stablehouse Custody Account. If
          you fail to withdraw all funds as above, we shall have the right to transfer any
          remaining digital assets contained therein to an isolated omnibus wallet and we
          reserve the right to deduct an administrative fee as necessary. In the event
          that such transfer has taken place, you have the right to retrieve your digital
          assets subject to satisfying our verification requirements. Where the Company
          deems it administratively necessary, you authorize us to liquidate your digital
          assets and return your funds (less any fees, rebates, debts owed to Stablehouse
          and/or damages to which we are entitled) to any bank account linked to your
          account, unless otherwise required by law.
        </dd>
        <dd>
          <b>
            iv. Remaining Funds After Stablehouse Account Termination Due to Fraud,
            Violation of Law, or Violation of These Terms of Use
          </b>
          <br />
          We shall maintain full custody of the Custodied Assets, funds and User
          data/information which may be turned over to governmental authorities in the
          event of Stablehouse Custody Accounts’ suspension/closure arising from fraud
          investigations, investigations of violation of law or violation of these Terms
          of Use.
        </dd>
        <dd>
          <b>v. Dormant Accounts</b>
          <br />
          Notwithstanding any provision of this Section on Termination, we may provide a
          written notice requiring you to close all of your open positions and withdraw
          all of your Custodied Assets from your Stablehouse Custody Account within 30
          days of the notice. In the event that you fail to do so, we may in our absolute
          discretion and without prior notice to you:
          <ul className="ml-8">
            <li>a) deem your Stablehouse Custody Account as a dormant account;</li>
            <li>b) close any open positions in any products or services;</li>
            <li>
              c) convert the Custodied Assets to a different type of Digital Asset (e.g.,
              from BTC to USDC). For the avoidance of doubt, we shall not be liable for
              any loss of profit, tax obligations or any other loss, damage or expense
              incurred by you resulting from such conversion;
            </li>
            <li>
              d) transfer such dormant account (including any Custodied Assets contained
              therein) to an isolated omnibus wallet. In the event that such transfer has
              taken place, you have the right to retrieve your digital assets from subject
              to satisfying our verification requirements, including completing KYC;
            </li>
            <li>
              e) charge a dormant account fee of US$10 value equivalent a month to cover
              the cost of maintaining the assets by us, its affiliates or any third-party
              and such fee shall be withdrawn directly from the dormant account on a
              monthly basis until there remains a nil balance; and
            </li>
            <li>
              f) close a dormant account at any time, and we will not be liable for any
              loss, damage or expense incurred by you as a result of the closure of a
              dormant account unless there was fraud or willful conduct by Stablehouse.
              Any assets in these dormant accounts will be transferred in accordance to
              paragraph (d) above. Where the Company deems it administratively necessary,
              you authorize us to liquidate your digital assets and return your funds
              (less any fees, rebates, debts owed to Stablehouse and/or damages to which
              we are entitled) to any bank account linked to your account, unless
              otherwise required by law. After a dormant account is closed, if you wish to
              continue to use our services, you will need to reach out to{' '}
              <a href={`mailto:${EMAIL.SUPPORT}`}>{EMAIL.SUPPORT}</a> to reactivate your
              account.
            </li>
          </ul>
        </dd>
        <dd>
          <b>vi. Following Termination of your Stablehouse Custody Account </b>
          <br />
          At termination of your Stablehouse Custody Account (for any reason), the Company
          will provide to you confirmation in writing of the following information:
          <ul className="ml-8">
            <li>a) the name and contact information of the Company;</li>
            <li>
              b) contact information allowing for you to request information about (i)
              your Stablehouse Account, (ii) the Company's business activities, or (iii)
              the making of a complaint to the Company;
            </li>
            <li>
              c) the type, value, date, precise time, and amount of all transactions
              applicable to your Stablehouse account; and
            </li>
            <li>
              d) the fees charged to your Stablehouse Account, including for conversions
              of your Digital Assets.
            </li>
          </ul>
        </dd>

        <dt>
          <h4>14. Indemnification</h4>
        </dt>
        <dd>
          By using the Stablehouse Custody Products, you agree to and hereby indemnify
          Stablehouse, its affiliates and their respective directors, members, officers,
          employees or agents) to the fullest extent permitted by applicable law, against
          any loss, liability, action, costs, claims, damages, penalties, interest,
          proceedings or expenses that you may suffer, directly or indirectly, in
          connection with the Stablehouse Custody Products, including (without limitation)
          the loss of any Custodied Assets or any part thereof and you hereby waive any
          and all claims that you may have against Stablehouse arising therefrom
          (including, in respect of any claim for direct, indirect, consequential or
          pecuniary damages) except for any claims arising as a result of the gross
          negligence, wilful misconduct, fraud or dishonesty on the part of Stablehouse.
        </dd>
        <dt>
          <h4>15. Assignment</h4>
        </dt>
        <dd>
          You may not assign any of your rights or obligations under these Custody Terms
          and Conditions without our prior written consent. Stablehouse may assign its
          rights or obligations under these Custody Terms and Conditions in whole or in
          part to (i) an affiliate (ii) an acquirer of Stablehouse's business or assets or
          (iii) a successor or surviving company pursuant to a merger or amalgamation.
        </dd>
        <dt>
          <h4>16. Governing law and disputed</h4>
        </dt>
        <dd>
          These Terms and Conditions will be governed by and construed and enforced solely
          in accordance with the laws of Bermuda and you consent and submit exclusively to
          the jurisdiction of the courts of Bermuda for the purposes of litigating any
          dispute arising out of these Terms and Conditions against Stablehouse, except
          with regard to injunctive relief.
        </dd>

        <dt>
          <h4>17. Force majeure</h4>
        </dt>
        <dd>
          We shall not be responsible for any losses, costs or damages suffered by you
          resulting directly or indirectly from war, riot, revolution, terrorism,
          pandemic, governmental actions or causes beyond our reasonable control or
          apprehension.
        </dd>
      </dl>
    </>
  );
};
