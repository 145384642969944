import React, { FC } from 'react';

export const LegalNotice: FC = () => {
  return (
    <>
      <h3 className="text-sh-b1 lg:text-sh-h3">Legal Notice</h3>

      <p>
        The financial products and services provided by Stablehouse are not available in
        the United States. Additional restrictions may apply.
      </p>
      <p>
        Neither our company nor our services have been registered or licensed under any
        United States laws and are not being offered in the United States or in any of its
        territories or possessions or areas subject to its jurisdiction or to its citizens
        or persons thereof. "United States" is herein defined meaning the United States of
        America (including the States and the District of Columbia), its territories, its
        possessions and other areas subject to its jurisdiction including the Commonwealth
        of Puerto Rico and "U.S. person" includes any national, citizen or resident of the
        United States, a corporation, partnership or other entity created or organised in
        and under the laws of the United States or any political subdivision thereof, and
        any estate or trust which is subject to United States federal income taxation
        regardless of the source of its income.
      </p>
    </>
  );
};
