import { EMAIL } from '../../../constants';
import { Link } from 'gatsby';
import React, { FC } from 'react';

export const TabTouEffective: FC = () => {
  /**
   * DOM
   */
  return (
    <>
      <>
        <h3>Terms of use (Website and Platform)</h3>
        <p>Version date – June 21, 2023</p>
        <p>Last updated – Sept 05, 2023</p>
        <p>
          Stablehouse Ltd. <strong>("Stablehouse")</strong> is an exempted company limited
          by shares, incorporated in Bermuda and licensed as a digital assets business by
          the Bermuda Monetary Authority with a Class M licence to operate as (a) a
          payment service provider business utilising digital assets, which includes the
          provision of services for the transfer of funds; (b) a digital asset exchange
          (c) a digital asset services vendor and (d) a business providing custodial
          wallet services.
        </p>
        <p>
          Stablesend Services EU Limited <strong>(“SH EU”)</strong> is a company limited
          by shares, incorporated in Ireland with its registered address at 3rd Floor,
          Waterways House, Grand Canal Quay, Dublin 2, Ireland.
        </p>
        <p>
          SH UK Strategies Ltd <strong>(“SH UK”)</strong> is a company limited by shares,
          incorporated in the UK with its registered address at First Floor, Templeback,
          10 Temple Back, Bristol BS1 6FL, UK. (Stablehouse, SH UK and SH EU are
          collectively known as, <strong>“Stablehouse Fiat” / “Company”</strong>)
        </p>
      </>

      <>
        <h3>1) Stablehouse website and platform</h3>
        <p>
          Stablehouse operates a digital assets custody and trading platform (the{' '}
          <strong>“Platform”</strong>) facilitating fiat on/off ramp and fiat to crypto
          and crypto to crypto trading in real time across blockchain.
        </p>
        <p>
          Stablehouse Fiat provides certain fiat services enabling you to make payments
          for services received from Stablehouse, via the Platform.{' '}
        </p>
        <p>
          Stablehouse will be responsible for performing all services relating to digital
          assets, including stablecoins. Stablehouse Fiat will be responsible only for
          services relating to the handling, dealing and processing of fiat payments and
          not for any services related to digital assets.
        </p>
        <p>
          In these Terms of Use, references to 'Stablehouse/we/our/us' are to Stablehouse
          and/or Stablehouse Fiat depending on the applicable services being performed and
          reference to 'you/users' are to users of the Platform and holders of Stablehouse
          Accounts, including owners, employees, agents, and affiliates of any company,
          corporation, partnership or other entity that is a user of the Platform and/or
          holder of a Stablehouse account.
        </p>
        <p>
          By accessing or using the Platform and this website (together, the{' '}
          <strong>“Site”</strong>), which is the property of Stablehouse, you hereby
          accept and agree to comply with the terms and conditions set forth in these
          Terms of Use, which are entered into between you, Stablehouse and Stablehouse
          Fiat as a multiparty agreement.
        </p>
        <p>
          These Terms of Use are a binding agreement between you, Stablehouse (including
          Stablehouse Fiat), and govern your access and use of the Site, which includes
          any text, graphics, user interfaces, information, data, tools, products,
          services, and other content (together, <strong>“Content”</strong>) available on
          or through the Site. These Terms of Use apply in addition to any other
          agreements and terms and conditions between you and Stablehouse, and other
          agreements, terms and conditions that govern your use of products, services,
          content, tools and information available on the Site (
          <strong>"Terms and Conditions"</strong>)
        </p>
        <p>
          The Company will provide you with not less than thirty days prior notice of any
          material change to the Terms and Conditions.
        </p>
        <p>
          You may contact us by e-mail at legal@stablehouse.com with questions about the
          Terms and Conditions.{' '}
        </p>
        <p>
          PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THE SITE. EACH TIME YOU
          USE THE SITE, YOUR USE INDICATES YOUR UNDERSTANDING, FULL ACCEPTANCE OF AND
          AGREEMENT TO ABIDE BY THESE TERMS OF USE. IN DOING SO, YOU ACKNOWLEDGE THAT YOU
          UNDERSTAND AND ACCEPT THE RISKS OF USING THE PLATFORM (A NON-EXHAUSTIVE LIST OF
          WHICH IS INCLUDED IN THESE TERMS OF USE) AND THE PRODUCTS AND SERVICES THAT WE
          PROVIDE. IF YOU DO NOT ACCEPT THESE TERMS OF USE OR ANY OTHER TERMS AND
          CONDITIONS, DO NOT USE THE SITE.
        </p>
      </>

      <>
        <h3>2) Stablehouse Accounts</h3>
        <p>
          If you are a new user, you must provide your full name and email address to sign
          up for an account ("Stablehouse Account") with Stablehouse to enable you to
          access the products and services available on the Platform (subject to any
          applicable Terms and Conditions).
        </p>
        <p>
          If you want to open a Stablehouse Account, you must complete our onboarding and
          verification requirements in order for us to comply with applicable anti-money
          laundering, anti-terrorist financing and international sanctions screening (
          <strong>"AML/ATF"</strong>) laws and regulations and you agree to provide such
          information as we reasonably request. The information that you provide must be
          complete, accurate and up-to-date.
        </p>
        <p>
          We may refuse your application for any reason. Unless required by any applicable
          law, we do not need to give you reasons for refusing or accepting an application
          for a Stablehouse Account.
        </p>
        <p>
          In order to access some of our products and services, we may require you to
          complete advanced verification checks and you will be required to accept our
          additional Terms and Conditions.
        </p>
        <p>
          We have the sole discretion as to the opening, operation and closure of a
          Stablehouse Account. Without limitation, we may, at any time for any reason,
          without notice or liability to you:
        </p>
        <ul className="list-dot">
          <li>vary, suspend, block, freeze or close a Stablehouse Account;</li>
          <li>
            specify, impose restrictions on or vary the products and services to which a
            Stablehouse Account has access on the Platform;
          </li>
          <li>
            impose or vary any limit applicable to the products and services made
            available through a Stablehouse Account;
          </li>
          <li>
            impose, vary or remove any conditions relating to the use of a Stablehouse
            Account and/or any products or services made available through the use of a
            Stablehouse Account.
          </li>
        </ul>
        <p>
          As part of our ongoing regulatory requirements, we will monitor your Stablehouse
          Account and your use of the Platform. At any time, we may require you to provide
          us with additional personal information as a condition of your continued access
          to, and use of, your Stablehouse Account. Until such information is provided to
          the satisfaction of Stablehouse, your use of your Stablehouse Account may be
          restricted or blocked. The manner in which such information must be provided to
          us is provided for on the Platform.
        </p>
        <p>See section on Termination of Terms of Use below.</p>
      </>

      <>
        <h3>3) Privacy policy</h3>
        <p>
          Your privacy is important to us. We encourage you to review our{' '}
          <Link to="/legal/?topic=privacy">Privacy Policy</Link>, which explains how we
          treat your personal information. Stablehouse's Privacy Policy is hereby
          incorporated into these Terms of Use.
        </p>
      </>

      <>
        <h3>4) Use of the site</h3>
        <p>
          All or any portion of the Site may not be available and may not function
          properly at any time, including during times of significant volatility or
          volume. This could result in the inability to buy or sell for periods of time
          and may also lead to support response time delays. Stablehouse shall not be
          liable for any losses resulting from or arising out of transaction delays.
        </p>
        <p>
          We make reasonable efforts and employ appropriate safeguards to avoid
          technological problems, but at any time, the Site may have and may cause
          technological problems such as viruses and other damaging computer programming
          routines or engines. Stablehouse is not liable for any technological problems
          and any impact they may have.
        </p>
        <p>
          We are not liable for any damage or injury caused by the performance or failure
          of performance of all or any portion of the Site.
        </p>
        <p>
          We are not liable for any defects, delays, or errors in or resulting from your
          use of the Site.
        </p>
        <p>
          We are not responsible for information on any third-party website that is
          referenced in, or accessible or connected by hyperlink to, the Site.
        </p>
        <p>
          If you access any third-party website through the Site or otherwise, you do so
          at your own risk. Hyperlinks to or from the Site do not constitute third-party
          endorsement of, sponsorship by, or affiliation with us.
        </p>
        <p>
          Stablehouse has the right, but not the obligation, to monitor and record
          activity on the Site and respond to and take such action as it deems
          appropriate. Without limitation, we may, at any time for any reason, without
          notice or liability to you:
        </p>
        <ul className="list-dot">
          <li>
            We may monitor and record activity on the Site for any reason or for no
            reason;
          </li>
          <li>
            We may investigate any complaint or reported violation of these Terms of Use,
            Terms and Conditions or our policies and procedures;
          </li>
          <li>
            We may report any activity we suspect may violate any law or regulation to
            regulators, law enforcement officials, or other persons or entities we deem
            appropriate;
          </li>
          <li>
            We may issue warnings, suspend, or terminate use of the Site, deny access to
            all or part of the Site or take any other action we deem appropriate;
          </li>
          <li>
            We may edit, remove or restrict any content provided by you to the Platform at
            any time without notice or providing a reason to you.
          </li>
        </ul>
        <p>
          There are various risks you assume in relying on the Content. Without
          limitation:
        </p>
        <ul className="list-dot">
          <li>The Content speaks only as of the date indicated, if any.</li>
          <li>
            We make reasonable efforts to provide accurate Content, but at times we may
            not promptly update or correct the Site even if we are aware it is inaccurate,
            outdated, or otherwise inappropriate.
          </li>
          <li>
            We may change all or any portion of the Site at any time without notice to
            you.
          </li>
          <li>
            We do not endorse the opinions of or warrant the accuracy of facts or other
            Content contributed by any third party.
          </li>
        </ul>
        <p>
          You will not transmit to Stablehouse or upload or make available on the Platform
          anything which is:
          <ol className="list-roman-lower">
            <li>commercial or promotional in nature;</li>
            <li>
              unlawful or deceptive or otherwise violates the legal privacy of others;{' '}
            </li>
            <li>
              capable of giving rise to any legal action against you or Stablehouse or its
              affiliates;{' '}
            </li>
            <li>
              in breach of or likely to infringe the intellectual property rights of any
              person; or
            </li>
            <li>
              which contains any harmful content, including viruses, malware, or any
              program, code or technology designed to disrupt, intercept, impair or affect
              the functionality of the Site.
            </li>
          </ol>
        </p>
        <p>
          You may not violate, interfere with, impair or circumvent the operation,
          security or privacy of the Site or the products and services available via the
          Platform, including carrying out, or attempting to carry out spamming, denial of
          service attacks or similar activities.
        </p>
      </>

      <>
        <h3>5) Stablehouse account security</h3>
        <p>
          If Stablehouse provides you with a password, you must keep your password and
          certain content confidential. You are solely responsible for maintaining the
          confidentiality and security of your password. You accept full responsibility
          for any use of your password. You must notify Stablehouse immediately of any
          actual or suspected loss, theft, or unauthorized use of your password.You agree
          to notify us immediately in the event that you learn or suspect that the
          security of your Stablehouse Account password may have been compromised.
        </p>
        <p>
          You further agree that you are responsible for any unauthorized use of your
          password that occurs before you have notified us and we have had a reasonable
          opportunity to act on that notice. We reserve the right to suspend or cancel
          your password, even without receiving such notice from you, if we suspect that
          it is being used in an unauthorized or fraudulent manner.
        </p>
        <p>
          Notwithstanding the above, you are responsible for monitoring your account and
          should promptly report any unauthorized or suspicious activity in your account
          to us at <a href={`mailto:${EMAIL.LEGAL}`}>{EMAIL.LEGAL}</a>.&nbsp;
        </p>
        <p>
          We are not obligated to inquire as to the authority or propriety of any use of
          or action taken under your password. We will not be responsible for any loss to
          you that arises from such use or action or from your failure to comply with
          these Terms of Use.
        </p>
        <p>
          We take reasonable security precautions when using the Internet, telephone, or
          other means to transport data or other communications, but we disclaim liability
          for any interception of data or communications.
        </p>
        <p>
          You may not disclose any Content that is contained within the password-protected
          portion of the Site to any third party, except to your professional advisors,
          and others with whom you share trading or investment decisions.
        </p>
      </>

      <>
        <h3>6) INTELLECTUAL PROPERTY</h3>
        <p>
          All rights, title and interest in and to the Site and the Content and any and
          all intellectual property contained therein is owned by and will remain the
          exclusive property of Stablehouse and its affiliates and licenses (including
          XBTO Trading LLC).
        </p>
        <p>
          Subject to these Terms of Use and the Terms and Conditions, we grant you a
          limited, non-transferable, non-sublicensable, non-exclusive, revocable license
          to use the Site and the Content for personal use until such time as your right
          to use the Site is terminated or expired.
        </p>
        <p>
          Except as expressly permitted in these Terms of Use and the Terms and
          Conditions, you may not, and you may not allow others to:
        </p>
        <ul>
          <li>
            sell, copy, modify, correct, enhance, create derivative works from, publish,
            broadcast, retransmit, reproduce, repackage, frame, store, distribute or
            exploit any Content or any portion of the Site;
          </li>
          <li>
            decompose, decompile, reverse engineer, disassemble or otherwise deconstruct
            all or any portion of the Site
          </li>
          <li>
            remove any copyright, trademark, or other proprietary notice or legend
            contained on (or printed from) the Site.
          </li>
        </ul>
        <p>
          You may print copies of any accessible portion of the Site only for your own
          personal use. You may discuss information you learn from the Site with your
          financial, legal or tax advisors, and others with whom you share trading or
          investment decisions.
        </p>
      </>

      <>
        <h3>7) Supported digital assets</h3>
        <p>
          A list of digital assets that Stablehouse supports on the Platform is available
          on the website. Stablehouse may discontinue or suspend support for any digital
          asset at any time and for any reason in our sole discretion, including due to
          changes in a given digital assets characteristics or due to compliance with laws
          or regulations.{' '}
        </p>
        <p>
          Under no circumstances should you attempt to use your Stablehouse Account to
          transfer or store digital assets in any form that is not supported by
          Stablehouse. Stablehouse assumes no responsibility or liability in connection
          with any attempt to use Stablehouse Account for digital assets that Stablehouse
          does not support.
        </p>
      </>

      <>
        <h3>8) Digital asset transactions</h3>
        <p>
          Upon being granted access to such services via your Stablehouse Account, you
          will have the ability to transfer, convert, track and store supported digital
          assets by giving instructions through the Platform (each such transaction, a “
          <strong>Digital Asset Transaction</strong>“) in connection with your Stablehouse
          Account.
        </p>
        <p>
          Stablehouse reserves the right to refuse to process or to cancel any pending
          Digital Asset Transaction in its discretion, which may be due to compliance with
          laws and regulations or in response to a court order, subpoena, other government
          order or to enforce transaction limits or for risk management purposes.{' '}
        </p>
        <p>
          Stablehouse cannot reverse a Digital Asset Transaction which has been broadcast
          to a digital asset network.{' '}
        </p>
        <p>
          You assume full responsibility and liability for any loss resulting from
          intentional or unintentional misuse of your Stablehouse Account, including,
          without limitation, any loss resulting from (i) depositing one type of digital
          asset to a wallet intended for another type of digital asset, regardless of
          whether the relevant digital asset network confirms the applicable Digital Asset
          Transaction; (ii) depositing a digital asset into an address that you did not
          intend; (iii) inserting incorrect transaction information into the Platform.
          Stablehouse assumes no responsibility or liability in connection with any of the
          foregoing.
        </p>
        <p>
          YOUR STABLEHOUSE ACCOUNT AND ANY OTHER ACCOUNT YOU MAY HAVE WITH US (AND ANY
          DIGITAL ASSETS HELD IN SUCH ACCOUNT) IS NOT A BANK ACCOUNT OR A DEPOSIT ACCOUNT.
          NO INTEREST WILL BE PAID ON ANY DIGITAL ASSETS YOU USE TO PURCHASE OR TRADE FOR
          ANY OTHER DIGITAL ASSET WITH OTHER USERS, AND ALL DIGITAL ASSETS DIRECTLY HELD
          BY US ARE NOT INSURED BY US OR ANY GOVERNMENT AGENCY. PLEASE NOTE, THE ACCOUNT
          IS NOT FDIC OR SIPC INSURED. YOU ARE POTENTIALLY SUBJECT TO THE CREDIT RISK OF
          STABLEHOUSE.
        </p>
        <p>
          For any supported digital asset on our Platform, Stablehouse processes Digital
          Asset Transactions according to the instructions received from its users and we
          do not guarantee the identity of any receiver of digital assets. You should
          verify all transaction information prior to submitting instructions to
          Stablehouse. We recommend transferring small amounts from one account to another
          to confirm that you have the correct address. A Digital Asset Transaction will
          be unconfirmed for a period of time pending sufficient confirmation of the
          transaction by the digital asset network. A Digital Asset Transaction is not
          complete while it is in a pending state. Digital assets associated with
          transactions that are in a pending state will be designated accordingly, and
          will not be included in your Stablehouse Account balance or be available to
          conduct transactions. Stablehouse may charge fees to process a Digital Asset
          Transaction on your behalf. You acknowledge that you will be responsible for
          paying any such fees out of your available proceeds. Stablehouse reserves the
          right to delay or cancel any Digital Asset Transaction if it perceives a risk of
          fraud or illegal activity, or otherwise within its discretion if we determine it
          is important for risk management.
        </p>
        <p>
          Stablehouse does not have any ability to prevent or mitigate attacks on any
          digital assets or their underlying protocols and networks. Stablehouse reserves
          the right to take any actions in the event of an attack, as determined in its
          sole discretion, including but not limited to: (i) if Stablehouse reasonably
          believes that a digital asset that it supports has been or may be compromised,
          or is or will be under attack, Stablehouse may immediately halt trading,
          deposits, and withdrawals for such digital asset; and (ii) if it is determined
          that such an attack caused the digital asset to greatly decrease in value,
          Stablehouse may discontinue trade activity on such digital asset partially or
          entirely. Stablehouse does not have any obligation to engage in any activities
          in relation to attacks on any digital assets or their underlying protocols and
          networks. Resolutions for an attacked digital asset will be determined on a
          case-by-case basis by Stablehouse in its sole discretion.{' '}
        </p>
        <p>
          WE ARE NOT RESPONSIBLE FOR ANY DISPUTES AMONG OR BETWEEN USERS REGARDING ANY
          DIGITAL ASSET TRANSACTION. WE DO NOT GUARANTEE THAT ANY ORDER YOU PLACE WILL BE
          EXECUTED. YOU HEREBY ACKNOWLEDGE AND AGREE THAT ONCE AN ORDER HAS BEEN EXECUTED,
          THE TRANSACTION IS LIKELY NOT TO BE REVERSIBLE.
        </p>
        <p>
          Stablehouse securely stores all digital asset private keys in our control in a
          combination of online and offline storage. As a result, it may be necessary for
          Stablehouse to retrieve certain information from offline storage in order to
          facilitate a Digital Asset Transaction in accordance with your instructions,
          which may delay the initiation or crediting of such Digital Asset Transaction
          for a period of time, which period of time may be significant. You acknowledge
          and agree that a Digital Asset Transaction facilitated by Stablehouse may be
          delayed for this reason.
        </p>
      </>

      <>
        <h3>9) Airdrops</h3>
        <p>
          You understand, acknowledge and agree that in the event that a third-party or
          software protocol attempts to or does distribute a digital asset, whether or not
          supported by Stablehouse (often known as an “airdrop” or “bootstrap”) to certain
          wallet addresses, we will treat such airdropped digital asset as we would treat
          any other unsupported digital asset. You agree and understand that airdropped
          digital assets do not create a relationship between us and the transferor,
          sender and/or the related network that created the airdropped digital asset, and
          further, that we are not subject to any obligation as it may relate to the
          transferor, sender or related network.
        </p>
      </>

      <>
        <h3>10) Exclusion of supplemental protocols</h3>
        <p>
          Unless specifically announced on the Site or other official public statement of
          Stablehouse, the term 'digital assets' when used in these Terms of Use excludes
          all other protocols and/or functionality which supplement or interact with such
          digital assets. This exclusion includes but is not limited to: colored coins,
          metacoins, side chains, or other derivative, enhanced, or forked protocols,
          tokens, or coins or other functionality, such as staking, protocol governance,
          and/or any smart contract functionality, which may supplement or interact with a
          digital asset that we support. If a digital asset is not supported, you may lose
          the asset with no opportunity to restore or regain such asset. Do not use your
          Stablehouse Account to attempt to request, receive, send, store, or engage in
          any other type of transaction or functionality involving any such protocol as
          Stablehouse is not configured to detect, secure, or process these transactions
          and functionality. Any attempted transactions in such items will result in loss
          of the item. You acknowledge and agree that supplemental protocols are excluded
          from every digital asset and that Stablehouse has no liability for any losses
          related to supplemental protocols.
        </p>
      </>

      <>
        <h3>11) Operation of digital assets protocols</h3>
        <p>
          Stablehouse does not own or control any underlying software protocols which
          govern the operation of digital assets supported on the Platform. In general,
          the underlying protocols are open source and anyone can use, copy, modify, and
          distribute them. By using the Platform, you acknowledge and agree
          <ol className="list-roman-lower">
            <li>
              that Stablehouse is not responsible for operation of the underlying
              protocols and that Stablehouse makes no guarantee of their functionality,
              security, or availability; and
            </li>
            <li>
              that the underlying protocols are subject to sudden changes in operating
              rules (“<strong>forks</strong>”), and that such forks may materially affect
              the value, function, and/or even the name of the digital asset stored on the
              Platform. In the event of a fork, you agree that Stablehouse may suspend
              Stablehouse operations (with or without advance notice to you) and that
              Stablehouse may, in its sole discretion, decide whether or not to support
              (or cease supporting) either branch of the forked protocol entirely. You
              acknowledge and agree that Stablehouse assumes no responsibility in respect
              of an unsupported branch of a forked protocol.
            </li>
          </ol>
        </p>
      </>

      <>
        <h3>
          12) OWNERSHIP AND CONTROL OF DIGITAL ASSETS (RELATING TO NON-CUSTODIAL SERVICES)
        </h3>
        <p>
          Title to each digital asset in your Stablehouse Account transfers to Stablehouse
          upon placing your digital assets on the Platform. Stablehouse shall have no
          liability for fluctuations in digital assets. Stablehouse may pool digital
          assets placed by you on the Platform with other digital assets while they are on
          the Platform
        </p>
        <p>
          By using Stablehouse’s services and platforms, you hereby grant Stablehouse the
          right to pledge, repledge, to sell, transfer, loan, hypothecate, or otherwise
          alienate digital assets and credit or debit your Stablehouse Account accordingly
          without prior notice to you and that by placing your digital assets with
          Stablehouse you are subject to the credit risk of Stablehouse. You further
          acknowledge and agree that you will not be entitled to any profits or proceeds
          earned by Stablehouse from the use of digital assets on the Platform.
          Notwithstanding the foregoing, you acknowledge and agree that in the event that
          there are outstanding amounts owed to us hereunder, including in your
          Stablehouse Account, Stablehouse reserves the right to debit your Stablehouse
          Account and/or to withhold amounts from funds you may transfer into such
          Stablehouse Account.
        </p>
        <p>
          Digital assets held in your Stablehouse Account are protected by private keys
          owned by Stablehouse. You may withdraw an equivalent amount of digital assets to
          those shown in your Stablehouse account at any time to separate blockchain
          wallets which you control or other third party custodial services in your name.
        </p>
        <p>
          You accept and understand that digital assets in your Stablehouse account do not
          qualify for insurance and/or protection by the Federal Deposit Insurance
          Corporation or the Securities Investor Protection Corporation.
        </p>
        <p>
          You acknowledge and agree that the Site may be subject to scheduled and
          unscheduled maintenance. You agree and understand that part of or all of
          Stablehouse may be periodically unavailable during such times (collectively, "
          <strong>Downtime</strong>"). You acknowledge and agree that Stablehouse is not
          responsible or liable during periods of Downtime. During such periods of
          Downtime, the prices of various digital assets may move, which means that you
          may gain or lose value in your Stablehouse Account. Stablehouse is not
          responsible for those gains or losses resulting from the Downtime.
        </p>
      </>

      <>
        <h3>13) Representatives</h3>
        <p>
          You represent and warrant to us at the time of entering into these Terms of Use
          and each Digital Asset Transaction:
        </p>
        <ul>
          <li>
            You are duly organized, validly existing and in good standing under the laws
            of the jurisdiction in which you are organized, if a legal entity, or are
            competent to enter into these Terms of Use, if a natural person. You have all
            necessary power and authority to enter into Digital Asset Transactions, to
            carry out obligations hereunder and to consummate the transactions
            contemplated hereby. By acknowledging these Terms of Use, the performance by
            you of your obligations hereunder and the consummation by you of the
            transactions contemplated hereby have been duly authorized by all requisite
            action on the part of the entity.
          </li>
          <li>
            These Terms of Use constitute a valid and legally binding agreement,
            enforceable against you in accordance with its terms, except as limited by
            applicable bankruptcy, insolvency, reorganization, moratorium, fraudulent
            conveyance, and any other laws of general application affecting enforcement of
            creditors’ rights generally.
          </li>
          <li>
            Neither acknowledgment of these Terms of Use, nor the consummation of any
            transaction or services contemplated hereby, does or will conflict with,
            violate or constitute a default under (i) any of your organizational
            documents, (ii) any statute, regulation, rule, judgment, order, decree,
            ruling, charge or other restriction of any government, governmental agency, or
            court to which you are subject or by which any of its assets or properties are
            bound, or (iii) under any agreement, debt or other instrument to which you are
            a party or by which any of your assets or properties are bound.
          </li>
          <li>
            Neither acknowledgement of these Terms of Use, nor the consummation of the
            Digital Asset Transactions, does or will violate any applicable law, rule or
            regulation or conflict with, violate or constitute a default under any
            material agreement to which you are a party.
          </li>
          <li>
            With respect to any Digital Asset you sell, transfer or deliver to us, you are
            the lawful owner of such digital asset with good and marketable title thereto,
            and you have the absolute right to sell, assign, convey, transfer and deliver
            such digital asset. Such digital asset is free and clear of any and all
            security interests, liens, pledges, claims (pending or threatened), charges,
            escrows, encumbrances or similar rights. You are the lawful owner of each
            wallet address you provide to us and have good title thereto. Each of your
            wallets is owned and operated solely for your benefit, and no person, other
            than you, has any right, title or interest in any wallet.
          </li>
          <li>
            You agree, understand, and acknowledge that the Company is not providing and
            will not provide any advisory or other similar services with respect to you,
            any person related to or affiliated with you, or any transaction subject to
            these Terms of Use. You further agree, represent and warrant that you are
            solely responsible for any decision to enter into a transaction subject to
            these Terms of Use, including the evaluation of any and all risks related to
            any such transaction; and in entering into any such transaction, you have not
            relied on any statement or other representation of the Company other than as
            expressly set forth herein.
          </li>
          <li>
            You hereby represent and warrant that you have not been listed on the U.S.
            Treasury Department's Specially Designated Nationals List or the U.S. Commerce
            Department's Denied Persons List, Unverified List, Entity List, or HM
            Treasury's financial sanctions regime and you will not use our Platform to
            conduct any illegal or illicit activity.
          </li>
        </ul>
      </>

      <>
        <h3>14) Fees</h3>
        <p>
          Stablehouse may earn fees when you purchase or sell digital assets on our
          platform. These fees are subject to change at any time. You agree to pay all
          applicable fees when you are using the Platform. For a list of all fees, please
          visit:{' '}
          <a
            href="https://app.stablehouse.com/fees"
            title="Fees can be viewed from the footer once you have logged in"
          >
            https://app.stablehouse.com/fees
          </a>
          .
        </p>
      </>

      <>
        <h3>15) NO SOLICITATION OR ADVICE</h3>
        <p>
          Nothing on the Site is an offer or solicitation to buy or sell any commodity,
          security, or other financial asset.
        </p>
        <p>
          Stablehouse is not providing any fiduciary, advisory, exchange or other similar
          services to, and does not act as agent or intermediary for, you, any person or
          entity related to you or affiliated with you (including without limitation any
          of your customers or clients).
        </p>
        <p>
          Although the Site may include trading-related information, nothing on the Site
          is a recommendation that you purchase, sell, or hold any commodity or other
          instrument, or that you pursue any trading style or strategy.
        </p>
        <p>
          We do not give any advice or make any representations through the Site as to
          whether any commodity, security, investment, or financial asset is suitable to
          you or will be profitable.
        </p>
        <p>
          Nothing on the Site is intended to be, and you should not consider anything on
          the Site to be, trading, investment, accounting, tax, legal, or professional
          advice of any kind.{' '}
        </p>
        <p>
          You agree that we are not liable for any action you take or decision you make in
          reliance on any Content.
        </p>
        <p>
          If you would like professional advice, you should consult with your own
          financial advisors, accountants, or attorneys regarding your individual
          circumstances and needs.
        </p>
        <p>
          THE PAST PERFORMANCE OF ANY COMMODITY, SECURITY, INVESTMENT, STRATEGY, OR STYLE
          IS NOT INDICATIVE OF NOR A GUARANTEE OF FUTURE PERFORMANCE.
        </p>
      </>

      <>
        <h3>16) CERTAIN RISKS IN USING THE PLATFORM</h3>
        <p>
          Your use of the Site is entirely at your own risk. You should consult your own
          legal, tax and financial advisors regarding these Terms of Use and your use of
          the Platform.
        </p>
        <p>
          Below is a non-exhaustive list of risks that you assume by using the Site and
          the Platform.
        </p>
        <ul className="ml-8">
          <li>
            a) We are an enterprise with little operating history. There can be no
            assurance that the Stablehouse will achieve its objective or that the products
            and services described on the Site will be successful.
          </li>
          <li>
            b) The market value of digital assets may change. Adverse market movements
            could lead to meaningful capital loss for you at the point of withdrawal. We
            provide no guarantee as to the underlying value of the digital assets traded
            on the Platform, the value of such assets may increase or decrease.
          </li>
          <li>
            c) While Stablehouse performs extensive due diligence on every
            token/crypto-currency it will list on the platform, it is not impossible that
            certain tokens will fail. Risks of sponsor defaults, lack of liquidity,
            blockchain reorganizations and forks are all possible outcomes when customers
            plan to invest in a specific token.
          </li>
          <li>
            d) While the Platform has all appropriate security measures in place to
            prevent the same, a situation could arise whereby a criminal hacks the
            Platform in order to steal digital assets or cause destruction resulting in
            the loss of digital assets. We provide no guarantee as to the security of the
            Platform. In addition, transactions in digital assets may be irreversible and
            losses due to accidental or fraudulent transactions may not be recoverable.
            The Company shall disclose to you any act that results in unauthorized access
            to, disruption, or misuse of the electronic systems or information stored on
            our systems including any breach of security leading to the loss or unlawful
            destruction or unauthorized disclosure of or access to such systems or
            information only where such breach leads to unauthorized access to or misuse
            of your information.
          </li>
          <li>
            e) We must comply with various legal requirements, including without
            limitation the Companies Act 1981 and the Digital Asset Business Act 2018 and
            the Digital Asset Business (Client Disclosure) Rules 2018 (each, as amended
            from time to time). Should any of those laws change, the legal requirements to
            which Stablehouse and you may be subject could differ materially from current
            requirements.
          </li>
          <li>
            f) General economic and business conditions may affect the activities of
            Stablehouse. Unexpected volatility or liquidity in the markets in which
            Stablehouse operates could impair its ability to carry out its business and
            could cause it to incur losses.
          </li>
        </ul>
      </>

      <>
        <h3>17) Liability</h3>
        <p>
          You agree that the liability of each of Stablehouse and Stablehouse Fiat under
          these Terms of Use is several and not joint, and each of Stablehouse and
          Stablehouse Fiat shall be liable only for their own respective obligations under
          these Terms of Use, and any breaches by them of those obligations. This means
          that each of them are responsible to you for their own breaches of these Terms
          of Use, and not for each other’s breaches.{' '}
        </p>
        <p>
          Neither Stablehouse and its affiliates (including XBTO Trading LLC), nor their
          directors, officers, employees or agents will be liable to you for any indirect,
          consequential, exemplary, punitive or special damages or lost profits, even if
          we have been advised of the possibility of any such damages or loss of profits.{' '}
        </p>
        <p>
          The total amount of any liability of Stablehouse and its affiliates (including
          XBTO Trading LLC) in connection with your use of the Site, the Platform or
          arising out of these Terms of Use, any Terms of Conditions or the products and
          services offered by Stablehouse is limited to the fees (if any) paid to
          Stablehouse by you to enable you to use the Site and the Platform.
        </p>
        <p>
          Stablehouse disclaims, to the fullest extent permitted by law, all
          representations and warranties, express or implied, of any kind with respect to
          the Site, the Platform or the products and services offered by Stablehouse.
        </p>
        <p>
          WITHOUT LIMITING OUR GENERAL DISCLAIMER, WE DO NOT WARRANT THE AVAILABILITY,
          ACCURACY, COMPLETENESS, TIMELINESS, FUNCTIONALITY, RELIABILITY, SEQUENCING,
          SUITABILITY, OR SPEED OF DELIVERY OF THE SITE OR ANY PART OF THE CONTENT.
        </p>
        <p>
          THIS DISCLAIMER OF LIABILITY APPLIES TO ANY AND ALL DAMAGES OR INJURY, INCLUDING
          THOSE CAUSED BY ANY FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION,
          DELETION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER VIRUS,
          COMMUNICATION LINE FAILURE, THEFT, DESTRUCTION OR UNAUTHORIZED ACCESS TO,
          ALTERATION OF OR USE OF ANY ASSET, WHETHER FOR BREACH OF CONTRACT, TORTIOUS
          BEHAVIOR, NEGLIGENCE, OR ANY OTHER CAUSE OF ACTION.
        </p>
      </>

      <>
        <h3>18) Indemnity</h3>
        <p>
          You agree to indemnify, defend, and hold harmless Stablehouse and its affiliates
          and licensors (including XBTO Trading LLC), and their respective directors,
          members, officers, employees or agents) to the fullest extent permitted by
          applicable law, against any loss, liability, action, costs, claims, damages,
          penalties, interest, proceedings or expenses that you may suffer, directly or
          indirectly, in connection with:
        </p>
        <ul className="list-dot">
          <li>your use of the Site,</li>
          <li>
            your breach of the Terms and Conditions or any representation, warranty, or
            covenant made by you in the Terms and Conditions,
          </li>
          <li>
            your violation of any applicable law, statute, ordinance, regulation, or of
            any third-party’s rights, or
          </li>
          <li>
            claims asserted by third parties that, if proven, would place you in breach of
            representations, warranties, covenants, or other provisions contained in these
            Terms of Use.
          </li>
        </ul>
        <p>
          You hereby waive any and all claims that you may have against Stablehouse
          arising therefrom (including, in respect of any claim for direct, indirect,
          consequential or pecuniary damages) except for any claims arising as a result of
          the gross negligence, wilful misconduct, fraud or dishonesty on the part of
          Stablehouse.
        </p>
      </>

      <>
        <h3>19) Termination of Terms of Use</h3>
        <ul>
          <li>
            <b>i. Suspension of Stablehouse Accounts</b>
          </li>
        </ul>
        <p>
          You agree that the Company shall have the right to immediately suspend your
          Stablehouse Account (and any accounts beneficially owned by related entities or
          affiliates), freeze or lock the digital assets or funds in all such accounts,
          and suspend your access to Stablehouse for any reason including if we suspect
          any such accounts to be in violation of these Terms of Use, our Privacy Policy,
          or any applicable laws and regulations. You agree that we shall not be liable to
          you for any permanent or temporary modification of your Stablehouse Account, or
          suspension or termination of your access to all or any portion of our services.
          We shall reserve the right to keep and use the transaction data or other
          information related to such suspended accounts.
        </p>
        <p>The above access control may also be applied in the following cases:</p>
        <ul className="list-dot">
          <li>
            Your Stablehouse Account is subject to a governmental proceeding, criminal
            investigation or other pending litigation;
          </li>
          <li>
            We detect unusual activities in or unauthorised access to your Stablehouse
            Account;
          </li>
          <li>
            We are required to do so by a court order or command by a
            regulatory/government authority.
          </li>
        </ul>

        <ul>
          <li>
            <b>ii. Cancellation / Termination of Stablehouse Accounts</b>
          </li>
        </ul>
        <p>
          In case of any of the following events, we shall have the right to directly
          terminate these Terms of Use by cancelling your Stablehouse Account, and shall
          have the right to permanently or temporarily suspend your Stablehouse Account
          upon the occurrence of the below:
        </p>
        <ul className="list-dot">
          <li>upon our termination of services to you;</li>
          <li>
            you allegedly register or register any other person’s name as a User more than
            once, directly or indirectly;
          </li>
          <li>
            the information that you have provided is untruthful, inaccurate, outdated or
            incomplete;
          </li>
          <li>your application for cancellation of your Stablehouse Account;</li>
          <li>you request that our services be terminated; and</li>
          <li>
            any other circumstances where we reasonably deem it appropriate to terminate
            our services within our risk appetite.
          </li>
        </ul>
        <p>
          Should your Stablehouse Account be terminated, all account and transactional
          information that meet data retention standards will be securely stored for at
          least 5 years in accordance with our Privacy Policy. In addition, if a
          transaction is deemed incomplete during the account termination process, we
          shall have the right to notify you of the process.
        </p>
        <p>
          You acknowledge that where you initiate termination of an account, right to
          erasure under GDPR, PIPA or other equivalent regulations, will also be subjected
          to the data retention protocols stated above.
        </p>
        <p>
          If we are made aware that any digital assets or funds held in your Stablehouse
          Account are stolen or otherwise are not lawfully possessed by you, we may, but
          have no obligation to, place an administrative hold on the affected funds and
          your Stablehouse Account. We may continue such hold until any dispute has been
          resolved and evidence of the resolution acceptable to us has been provided. You
          agree that we will have no liability or responsibility for any such hold, or for
          your inability to withdraw Digital Assets or funds or execute trades during the
          period of any such hold.
        </p>
        <ul>
          <li>
            <b>
              iii. Remaining Funds After Stablehouse Account Cancellation / Termination
            </b>
          </li>
        </ul>
        <p>
          Except as set forth in paragraph iv. below, once a Stablehouse Account is
          closed/withdrawn, all remaining fees, charges and liabilities owed to
          Stablehouse will be netted off immediately. In relation to termination fees, Stablehouse 
          shall have the right to charge an administrative fee of US$10 value equivalent 
          upon the termination of a Stablehouse Account. Upon netting off all outstanding
          charges to Stablehouse (if any), you will have 5 business days to withdraw all
          Digital Assets or funds from your Stablehouse Account. Any remaining balance (if
          any) will be payable immediately to Stablehouse.
        </p>
        <ul>
          <li>
            <b>
              iv. Remaining Funds After Account Termination Due to Fraud, Violation of
              Law, or Violation of These Terms
            </b>
          </li>
        </ul>
        <p>
          We shall maintain full custody of the digital assets, funds and user
          data/information which may be turned over to governmental authorities in the
          event of Stablehouse Accounts’ suspension/closure arising from fraud
          investigations, investigations of violation of law or violation of these Terms.
        </p>
        <ul>
          <li>
            <b>v. Dormant Account</b>
          </li>
        </ul>
        <p>
          Notwithstanding any provision of this Section on Termination of Terms of Use, we
          may provide a written notice requiring you to close all of your open positions
          and withdraw all of your Digital Assets from your Stablehouse Account within 30
          days of the notice.
        </p>
        <p>
          In the event that you fail to do so, we may in our absolute discretion and
          without prior notice to you:
        </p>
        <ul className="ml-8">
          <li>a) deem your Stablehouse Account as a dormant account;</li>
          <li>b) close any open positions in any products or services; </li>
          <li>
            c) convert the digital assets to a different type of digital asset (e.g., from
            BTC to USDC). For the avoidance of doubt, we shall not be liable for any loss
            of profit, tax obligations or any other loss, damage or expense incurred by
            you resulting from such conversion;
          </li>
          <li>
            d) charge a dormant account fee of US$10 value equivalent a month to cover the
            cost of maintaining the assets by us, its affiliates or any third-party and
            such fee shall be withdrawn directly from the dormant account on a monthly
            basis until there remains nil balance; and
          </li>
          <li>
            e) close a dormant account at any time, and we will not be liable for any
            loss, damage or expense incurred by you as a result of the closure of a
            dormant account unless there was fraud or willful conduct by Stablehouse.
            After a dormant account is closed, if you wish to continue to use our
            services, you will need to reach out to{' '}
            <a href={`mailto:${EMAIL.SUPPORT}`}>{EMAIL.SUPPORT}</a> to reactivate your
            account.
          </li>
        </ul>
        <ul>
          <li>
            <b>vi. Following Termination of your Stablehouse Account</b>
          </li>
        </ul>
        <p>
          At termination of your Stablehouse Account (for any reason), the Company will
          provide to you confirmation in writing of the following information:
        </p>
        <ul className="ml-8">
          <li>a) the name and contact information of the Company;</li>
          <li>
            b) contact information allowing for you to request information about (i) your
            Stablehouse Account, (ii) the Company's business activities, or (iii) the
            making of a complaint to the Company;
          </li>
          <li>
            c) the type, value, date, precise time, and amount of all transactions
            applicable to your Stablehouse account; and
          </li>
          <li>
            d) the fees charged to your Stablehouse Account, including for conversions of
            your digital assets.
          </li>
        </ul>
      </>

      <>
        <h3>20) Governing law and disputes</h3>
        <p>
          With respect to Stablehouse Ltd., these Terms of Use will be governed by and
          construed and enforced solely in accordance with the laws of Bermuda and you
          consent and submit exclusively to the jurisdiction of the courts of Bermuda for
          the purposes of litigating any dispute arising out of these Terms of Use against
          Stablehouse Ltd., except with regard to injunctive relief.
        </p>
        <p>
          With respect to SH EU, these Terms of Use will be governed by and construed and
          enforced solely in accordance with the laws of Ireland and you consent and
          submit exclusively to the jurisdiction of the courts of Ireland for the purposes
          of litigating any dispute arising out of these Terms of Use against Stablehouse
          Fiat, except with regard to injunctive relief.
        </p>
        <p>
          With respect to SH UK, these Terms of Use will be governed by and construed and
          enforced solely in accordance with the laws of England and Wales and you consent
          and submit exclusively to the jurisdiction of the courts of England and Wales
          for the purposes of litigating any dispute arising out of these Terms of Use
          against Stablehouse Fiat, except with regard to injunctive relief.
        </p>
      </>

      <>
        <h3>21) General</h3>
        <p>
          These Terms of Use are subject to change at our discretion and you will be bound
          by any revised versions of these Terms of Use that we post on the Site and (save
          as required under applicable law) we shall not provide any notice to you of such
          changes.
        </p>
        <p>
          Modifications will be effective immediately upon posting unless we indicate
          otherwise.
        </p>
        <p>
          You may not assign any of your rights or obligations under these Terms of Use
          without our prior written consent. We may assign these Terms of Use in whole or
          in part at any time without your consent.{' '}
        </p>
        <p>
          Failure by us to exercise, or any delay in exercising, any right or remedy
          provided under these Terms of Use or by law shall not constitute a waiver of
          that or any other right or remedy, nor shall it preclude or restrict any further
          exercise of that or any other right or remedy.
        </p>
        <p>
          No single or partial exercise of any right or remedy provided under these Terms
          and Conditions or by law shall preclude or restrict the further exercise of that
          or any other right or remedy.
        </p>
        <p>
          If any provision in these Terms of Use shall be held to be illegal, invalid or
          unenforceable, in whole or in part, such provision or part shall to that extent
          be deemed not to form part of these Terms of Use, but the legality, validity and
          enforceability of the remainder of these Terms of Use shall not be affected.
        </p>
      </>

      <>
        <h3>22) Force majeure</h3>
        <p>
          We shall not be responsible for any losses, costs or damages suffered by you
          resulting directly or indirectly from war, riot, revolution, terrorism,
          pandemic, governmental actions or causes beyond our reasonable control or
          apprehension.
        </p>
      </>
    </>
  );
};
