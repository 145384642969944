import React, { FC, useEffect, useState } from 'react';
import { NavItem, NavItemType } from '../components/NavItem';
import { parse } from 'query-string';
import { PrivacyPolicy, LegalNotice, Licenses, TermsOfUse } from '../components/legal';
import { PageLayout } from '../components/layout';

import './legal.scss';
import { MainContent } from '../components/layout/main-content';

export enum LEGAL_TOPIC {
  PRIVACY = 'privacy',
  TERMS_OF_USE = 'terms-of-use',
  NOTICE = 'notice',
  LICENSES = 'licenses',
}

interface Props {
  location: any;
}

const PageLegal: FC<Props> = ({ location }) => {
  /**
   * State
   */
  const [chosenTopic, setChosenTopic] = useState<LEGAL_TOPIC | null>(null);

  /**
   * Methods
   */
  const handleTopicChange = () => {
    const { topic, name } = parse(location.search);
    setChosenTopic((topic as unknown as LEGAL_TOPIC) || LEGAL_TOPIC.PRIVACY);
  };

  /**
   * Hooks
   */
  useEffect(() => handleTopicChange(), [location.search]);

  /**
   * DOM
   */
  return (
    <PageLayout bgCls="bg-accent-light">
      <MainContent cls="grid grid-cols-1 lg:grid-cols-14 lg:gap-x-3 py-6 terms-privacy">
        <div className="col-span-full col-start-1 xl:col-span-12 xl:col-start-2">
          <div className="flex flex-col md:flex-row">
            <div className="py-12">
              <nav className="w-full">
                <ul>
                  <NavItem
                    type={NavItemType.legal}
                    className="py-2"
                    selectedCls="bg-transparent underline"
                    linkClassName={'w-full block no-underline'}
                    selectedLinkCls="w-full block underline"
                    shouldParseSearch={true}
                  />
                </ul>
              </nav>
            </div>
            <div className="flex-1 md:pl-12 lg:pl-16 py-12">
              {chosenTopic === LEGAL_TOPIC.PRIVACY && (
                <PrivacyPolicy />
              )}
              {chosenTopic === LEGAL_TOPIC.TERMS_OF_USE && (
                <TermsOfUse />
              )}
              {chosenTopic === LEGAL_TOPIC.NOTICE && (
                <LegalNotice />
              )}
              {chosenTopic === LEGAL_TOPIC.LICENSES && (
                <Licenses />
              )}
            </div>
          </div>
        </div>
      </MainContent>
    </PageLayout>
  );
};

export default PageLegal;
