import React, { FC } from 'react';
import { URLS } from '../../constants';

export const Licenses: FC = () => {
  return (
    <>
      <h3 className="text-sh-b1 lg:text-sh-h3">Licenses</h3>

      <p>
        Stablehouse Ltd is an exempted Bermuda company licensed by the{' '}
        <a className="underline" href={URLS.BMARE}>
          Bermuda Monetary Authority
        </a>{' '}
        and regulated under the Digital Assets Business Act 2018.
      </p>
    </>
  );
};
