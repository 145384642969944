import React, { FC } from 'react';
import { EMAIL } from '../../constants';

export const PrivacyPolicy: FC = () => {
  return (
    <>
      <h3>Privacy Policy</h3>
      <p>Version 9 – May 31, 2023</p>
      <p>
        This Privacy Policy sets forth the information which the Company is required under
        the Bermuda Personal Information Protection Act 2016 (“
        <strong>PIPA</strong>”) to provide to you concerning the policy and procedures of
        Stablehouse Ltd. and its affiliates (collectively, the “Company”) with respect to
        personal information that the Company may collect through this website (including
        any mobile application or version of the website, collectively, the “Site”).
      </p>
      <p>
        This Privacy Policy may be changed at any time which will be indicated by the date
        on the top of this notice, provided that if we make any substantial change to this
        Privacy Policy, we will notify you by email and will post the updated Privacy
        Policy here. This Privacy Policy applies only to the personally identifiable
        information of individuals (the “<strong>Personal Information</strong>
        ”), not the trade secrets or other intellectual property of any person.
      </p>
      <p>We may collect the following types of Personal Information from you:</p>
      <ul className="list-dot">
        <li>
          Personal Identification Information: Full name, date of birth, nationality,
          gender, signature, utility bills, photographs, phone number, residential
          address, and/or email.
        </li>
        <li>
          Formal Identification Information: Government issued identity documents such as
          Passport, Driver's License, and/or any other information deemed necessary to
          comply with our legal obligations under financial or anti-money laundering laws.
        </li>
        <li>
          Institutional Information: Employer Identification number (or comparable number
          issued by a government), proof of legal formation (e.g. Articles of
          Incorporation), personal identification information for all material beneficial
          owners, controllers or directors.
        </li>
        <li>
          Financial Information: Bank account information, source of wealth, annual
          income, payment card primary account number (PAN), transaction history, trading
          data, and/or tax identification.
        </li>
        <li>
          Transaction Information: Information about the transactions you make on our
          Site, such as the name of the recipient, your name, the amount, purpose and/or
          timestamp.
        </li>
        <li>
          Employment Information: Office location, name of employer, job title, and/or
          description of role.
        </li>
        <li>Correspondence: information provided to our customer support team.</li>
      </ul>
      <p>Information we collect from you automatically:</p>
      <p>
        To the extent permitted under the applicable law, we may collect certain types of
        information automatically, such as whenever you interact with the Site. This
        information helps us address customer support issues, improve the performance of
        our Site, provide you with a streamlined and personalized experience, and protect
        your account from fraud by detecting unauthorized access.
      </p>
      <p>Information collected automatically includes:</p>
      <ul className="list-dot">
        <li>
          Online Identifiers: Geo location/tracking details, browser name and version,
          and/or personal IP addresses.
        </li>
        <li>
          Usage Data: Authentication data, security questions, click-stream data, public
          social networking posts, information about your behavior and other data
          collected via cookies and similar technologies
        </li>
      </ul>
      <p>
        You acknowledge that by providing your Personal Information and other Information
        to the Company that you consent to the processing of your data in accordance with
        this Privacy Notice. If you do not agree with or you are not comfortable with any
        aspect of this Privacy Policy, you should immediately discontinue access.
      </p>
      <p>
        The Company may collect, use and share relevant Personal Information in accordance
        with this Privacy Policy and our Terms of Use, with your knowledge and consent
        typically for the following purposes:
      </p>
      <ul className="list-dot">
        <li>
          To notify you about our products and services, features, new updates and
          improvements of our products and services.
        </li>
        <li>To manage and maintain your account with us.</li>
        <li>To process transactions and applications you make.</li>
        <li>To enable user analytics and tracking of user behavior.</li>
        <li>
          To make risk assessments, perform onboarding and provide customer service.
        </li>
        <li>
          To provide information to our KYC/KYT third party service providers to assist us
          with performing identity verifications, due diligence, watchlist and negative
          media screenings in accordance with our KYC, AML/ATF requirements.
        </li>
        <li>
          To provide KYC information to our third party business partners that are token
          issuers to streamline your onboarding process with them.
        </li>
        <li>
          To comply with applicable laws in any country and fulfill our obligations with
          any regulatory, legal, or good practice requirement.
        </li>
        <li>
          To comply with our obligations under any reporting agreement entered with any
          tax authority or revenue service(s).
        </li>
        <li>
          To prevent and detect money-laundering, terrorism, fraud, or other crimes.
        </li>
      </ul>

      <p>
        The Company does not disclose any of the Personal Information to anyone, other
        than to its affiliates, and except as permitted by law, to the Company’s attorneys
        and regulators and to certain third party service providers that we have a
        contractual relationship with who will process the Personal Information for the
        purposes identified above. In particular, we use third party providers that
        provide the services below (including but are not limited to): (i) hosting of this
        Site; (ii) ID verification and watchlist / sanctions screening services such as
        Onfido Inc., Sum and Substance Ltd. and IVXS UK Limited (trading as
        ComplyAdvantage); (iii) Know-Your-Transactions (“KYT”) services such as
        Chainalysis and TRM Labs Inc.; (iv) Travel Rule compliance services such as
        Notabene; (v) certain third party stablecoin issuers; (vi) wait list and referral
        services; (vii) data analytics and tracking services on the Site; (viii)
        customer identity management solutions for login; and (ix) customer support and any
        other marketing services.
      </p>
      <p>
        Third party sites are governed by their own privacy policies and we have included
        some links below for your information:
      </p>
      <ol className="list-roman-lower">
        <li>
          <a href="https://onfido.com/privacy/">Onfido</a>
        </li>
        <li>
          <a href="https://sumsub.com/privacy-notice/">Sumsub</a>
        </li>
        <li>
          <a href="https://complyadvantage.com/privacy-notice">ComplyAdvantage</a>
        </li>
        <li>
          <a href="https://www.chainalysis.com/privacy-policy/">Chainalysis</a>
        </li>
        <li>
          <a href="https://assets.website-files.com/6082dc5b67056233213587a4/60ca91aa058ced32085dc998_TRM_Privacy%20Policy.pdf">
            TRM Labs
          </a>
        </li>
        <li>
          <a href="https://notabene.id/privacy-policy">Notabene</a>
        </li>
        <li>
          <a href="https://www.okta.com/privacy-policy/">Auth0 / Okta</a>
        </li>
        <li>
          <a href="https://www.heap.io">Heap</a>
        </li>
      </ol>

      <p>
        Your information will only be provided to a third party where we are satisfied
        that such party has adequate measures in place to protect Personal Information. We
        regularly review and implement up-to-date technical security measures when
        processing your Personal Information.
      </p>
      <p>
        The Company retains data for a minimum of five years, however we will not keep
        your Personal Information for longer than necessary for our purposes. Certain data
        can be retained for longer periods as required by the laws of Bermuda (Regulations
        15 and 16 of the Bermuda Proceeds of Crime (Anti-Money Laundering and
        Anti-Terrorist Financing) Regulations 2008.
      </p>
      <p>
        Your Personal Information is stored in Ireland and accessed via secure connections
        only. Where Personal Information is transferred overseas, the Company continues to
        ensure compliance with PIPA in relation to that information.
      </p>
      <p>
        This Site may use “cookies,” which may automatically collect certain information
        and data. “Cookies” are small pieces of data sent to your computer browser from
        the Company’s web server and stored on your computer’s hard drive. The data
        identifies you as a unique user and facilitates your ongoing access to and use of
        this Site. Cookies also help the Company diagnose problems with the Company’s
        server. By using this Site you consent to the Company collecting and using Cookies
        for these purposes.
      </p>
      <p>
        The Company seeks to carefully safeguard your Personal Information and, to that
        end, restrict access to nonpublic Personal Information to those employees and
        certain other persons, including service providers, such as a third party that
        hosts this Site, who need to know such information. Third parties must in all
        cases agree to a strict duty to keep all Personal Information confidential and to
        use it only for the purpose for which it was obtained.
      </p>
      <p>
        Under certain circumstances and subject to certain exceptions, you have the right
        to:
      </p>
      <ul className="internal">
        <li>Request access to the personal information which we hold about you</li>
        <li>
          Request correction of any inaccurate or incomplete personal information that we
          hold about you
        </li>
        <li>
          Request that we cease or not begin the use of your personal information for the
          purposes of advertising, marketing or public relations, or where the use is
          causing or likely to cause substantial damage or distress
        </li>
        <li>
          Request erasure of your personal information where that personal information is
          no longer relevant for the purpose of its use.
        </li>
      </ul>

      <p>
        The Company has appointed Stephanie Shih as our Privacy Officer, to oversee
        compliance with this Privacy Notice. If you have any questions or comments about
        this Privacy Notice, or wish to make use of any of your Personal Information
        rights referred to above, please feel free to contact the Privacy Officer at:
      </p>
      <p>
        Email:&nbsp;<a href={`mailto:${EMAIL.LEGAL}`}>legal@stablehouse.com</a>
      </p>
      <p>Address: Ideation House, G/F, 94 Pitts Bay Road, Pembroke, Bermuda HM08</p>
    </>
  );
};
